// src/mixins/fetchMountainsMixin.js

import {REMOTE_FETCH, TOMORROW_IO_URL} from "@/constants";
import * as Sentry from "@sentry/browser";

// Function to update the offset parameter in the URL
function updateUrlOffset(url, newOffset) {
    // Create a URL object from the URL string
    const urlObj = new URL(url);

    // Set the new value for the offset parameter
    urlObj.searchParams.set('offset', newOffset);

    // Return the updated URL string
    return urlObj.toString();
}
// Helper method to handle and log errors
function handleError(error, customMessage = 'An error occurred') {
    console.error(`${customMessage}:`, error);

    // If you're using a monitoring service like Sentry, you can log it as well
    if (typeof Sentry !== 'undefined') {
        Sentry.captureException(error);
    }
}

let airTableHeaders = {
    // "Access-Control-Allow-Headers": "X-API-Key",
    // "Content-Type": "application/json",
    "X-API-Key": "AIRTABLE",
};
export default {
    data() {
        return {
            isMountainsLoaded: false,
            mountains: [],
            passes: [],
            mountainOffset: 0,
            mountainBatchLimit: 20, // Number of mountains to load per scroll
            timeout: 250,   // To get all 5 pages of 100 results without hitting the API limit cap
            loadAll: false, // 'Slowly' load all mountains on mountains page
        };
    },
    methods: {
        async addMountainToFavorites(userId, mountainId) {
            try {
                const favorites = this.user.fields['Favorites'] || [];

                // Check if `mountainId` is not already in favorites, then add it.
                const index = favorites.indexOf(mountainId);
                if (index === -1) {
                    favorites.push(mountainId);
                } else {
                    throw new Error('Mountain ID already in favorites');
                }

                // Update the user's favorites in AirTable using fetch.
                const response = await fetch(
                    `${REMOTE_FETCH}?table=Users/${userId}`,
                    {
                        method: 'PATCH',
                        headers: airTableHeaders,
                        body: JSON.stringify({
                            "fields": {
                                "Favorites": favorites
                            }
                        })
                    }
                );

                // Check if response is okay.
                if (!response.ok) {
                    throw new Error('Network response was not ok' + response.statusText);
                }

                // Log the response and update the UI accordingly.
                return await response.json();
            } catch (error) {
                handleError('Error adding mountain to favorites: ', error);
            }
        },
        async removeMountainFromFavorites(userId, mountainId) {
            try {
                const favorites = this.user.fields['Favorites']

                // Check if `mountainId` exists in favorites and remove it.
                const index = favorites.indexOf(mountainId);
                if (index > -1) {
                    favorites.splice(index, 1);
                } else {
                    let msg = 'Mountain ID not found in favorites'
                    handleError(msg)
                    throw new Error(msg);
                }

                // Update the user's favorites in AirTable using fetch.
                const response = await fetch(
                    `${REMOTE_FETCH}?table=Users/${userId}`,
                    {
                        method: 'PATCH',
                        headers: airTableHeaders,
                        body: JSON.stringify({
                            "fields": {
                                "Favorites": favorites
                            }
                        })
                    }
                );

                // Check if response is okay.
                if (!response.ok) {
                    throw new Error('Network response was not ok' + response.statusText);
                }

                // Log the response and update the UI accordingly.
                const responseData = await response.json();
                return responseData;

            } catch (error) {
                handleError('Error removing mountain: ', error);
            }
        },
        async fetchAllRecords(url, records = [], offset = null) {
            const response = await fetch(url, {
                // mode: "no-cors",
                headers: airTableHeaders,
            });

            // Check if the response is ok
            if (!response.ok) {
                Sentry.captureException(response);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            // Check if data is not null and has a 'records' property
            // Collect the records from this response
            if (data && Array.isArray(data.records)) {
                records.push(...data.records.map(record => ({ id: record.id, ...record.fields })));
            }

            // Check if there is an offset for the next page
            if (data.offset) {
                // Recursive call to fetch more records, passing the new offset
                return this.fetchAllRecords(url, records, data.offset);
            }

            // Return the collected records once all pages are fetched
            return records;
        },
        async fetchPasses() {
            let url = `${REMOTE_FETCH}?table=Passes&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc`;
            const response = await fetch(url, {
                headers: airTableHeaders,
            });
            const data = await response.json();
            this.passes.push(...data.records.map((record) => {
                const sortedMountainNames = Array.isArray(record.fields['Mountain Names'])
                    ? record.fields['Mountain Names'].sort()
                    : [];
                const fields = {
                    ...record.fields,
                    'Unique Region Names': record.fields['Unique Region Names'].sort(),
                    'Unique Region Count': record.fields['Unique Region Names'].length,
                    'Mountain Count': sortedMountainNames.length
                };
                return { id: record.id, ...fields };
            }));
        },
        async fetchUphill() {
            let url = `${REMOTE_FETCH}?table=Mountains&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc`;
            // Append the 'view' parameter to your URL
            url += `&view=${encodeURIComponent("Uphill View")}`;
            const response = await fetch(url, {
                headers: airTableHeaders,
            });
            const data = await response.json();
            this.mountains.push(...data.records.map((record) => ({ id: record.id, ...record.fields })));
        },
        async fetchMountains() {
            let offset = null;
            const allMountains = [];

            do {
                // Construct the URL with offset if it exists
                let url = `${REMOTE_FETCH}?table=Mountains&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc`;
                if (offset) {
                    url += `&offset=${offset}`;
                }

                try {
                    const response = await fetch(url, {
                        headers: airTableHeaders,
                    });

                    if (!response.ok) {
                        let msg = `HTTP error! Status: ${response.status}`;
                        handleError(msg);
                        throw new Error(msg);
                    }

                    const data = await response.json();

                    // Add the fetched records to the allMountains array
                    const newMountains = data.records.map(record => ({ id: record.id, ...record.fields }));
                    allMountains.push(...newMountains);

                    // Update offset for the next fetch, if available
                    offset = data.offset || null;
                } catch (error) {
                    handleError('Error fetching mountains:', error);
                    throw error;
                }

                // Optional: Add a small delay between requests to avoid rate limiting
                await new Promise(resolve => setTimeout(resolve, 250));

            } while (offset); // Continue until there is no offset, meaning all data has been fetched

            // Remove duplicates and sort once all mountains are fetched
            const uniqueMountains = allMountains.filter(
                (mountain, index, self) =>
                    index === self.findIndex(m => m.id === mountain.id)
            ).map(mountain => ({
                ...mountain,
                // Safeguard: Default empty arrays for fields expected to be arrays or strings
                "Pass Names": mountain["Pass Names"] || [],
                "Region Names": mountain["Region Names"] || [],
            }));

            // Sort by Name
            uniqueMountains.sort((a, b) => a.Name.localeCompare(b.Name));

            this.mountains = uniqueMountains;
        },
        // Don't use offset on homepage, no more than 100 ever
        async fetchMountainsWithLogos() {
            // Construct the URL, adding offset if it exists
            let url = `${REMOTE_FETCH}?table=Mountains&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc&filterByFormula=NOT({Logo}='')`;
            try {
                const response = await fetch(url, {
                    headers: airTableHeaders,
                });

                if (!response.ok) {
                    let msg = `HTTP error! Status: ${response.status}`;
                    handleError(msg);
                    throw new Error(msg);
                }

                const data = await response.json();

                // Map new records and filter out duplicates by ID
                const newMountains = data.records.map(record => ({ id: record.id, ...record.fields }));
                const uniqueMountains = newMountains.filter(
                    newMountain => !this.mountains.some(existingMountain => existingMountain.id === newMountain.id)
                );

                // Add only unique records to mountains array
                this.mountains.push(...uniqueMountains);

                // Sort mountains by Name
                this.mountains.sort((a, b) => a.Name.localeCompare(b.Name));
            } catch (error) {
                handleError('Error fetching mountains:', error);
                throw error; // Allow loadMoreMountains to handle it
            }
        },
        // Take either a number or AirTable ID
        async fetchMountainById(id) {
            try {
                let url = `${REMOTE_FETCH}?table=Mountains`;
                let filterFormula = '';
                // Check if 'id' is a string and starts with "rec"
                if (typeof id === 'string' && id.startsWith('rec')) {
                    filterFormula = `RECORD_ID()='${id}'`;
                }
                // Check if 'id' is a number
                else if (!isNaN(id) && Number.isInteger(Number(id))) {
                    // Assuming you have a field name that stores these numeric IDs, for example, 'NumericID'
                    filterFormula = `{ID}=${id}`;
                }
                // Handle the case where 'id' is neither a 'rec' string nor a number
                else {
                    throw new Error("Invalid ID: ID must be a 'rec' string or an integer.");
                }

                url += `&filterByFormula=${encodeURIComponent(filterFormula)}`;

                const response = await fetch(url, {
                    headers: airTableHeaders,
                });

                if (response.ok) {
                    const data = await response.json();
                    // Assuming the ID is unique, return the first record
                    return data.records[0];
                } else {
                    Sentry.captureException(response);
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                handleError(error);
                throw error;
            }
        },
        async fetchMountainsByIds(mountainIds) {
            if (!mountainIds || mountainIds.length === 0) {
                return
            }
            // Construct the URL for fetching mountains by IDs
            let url = `${REMOTE_FETCH}?table=Mountains&filterByFormula=OR(`;
            mountainIds.forEach(id => {
                url += `RECORD_ID()='${id}',`
            });
            url = url.slice(0, -1) + ')'; // Removing the last comma and closing the parenthesis.

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: airTableHeaders,
                });

                if (response.ok) {
                    const data = await response.json();
                    return data.records;
                } else {
                    Sentry.captureException(response);
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                handleError(error);
                throw error;
            }
        },
        async fetchUserByUniqueId(uniqueId) {
            let url = `${REMOTE_FETCH}?table=Users`;
            try {
                let filterFormula = `({UniqueID} = '${uniqueId}')`;
                url += `&filterByFormula=${encodeURIComponent(filterFormula)}`;

                const response = await fetch(url, {
                    headers: airTableHeaders,
                });

                if (response.ok) {
                    const userData = await response.json();
                    // Check if records array is not empty, and return the first record if found
                    if (userData.records && userData.records.length > 0) {
                        return userData.records[0];
                    } else {
                        throw new Error('User not found');
                    }
                } else {
                    Sentry.captureException(response);
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                handleError(error);
                throw error;
            }
        },
        async setFavoriteMountain(userId, mountainId) {
            let url = `${REMOTE_FETCH}?table=Users/${userId}`;
            try {
                let filterFormula = `({Favorites} = '${mountainId}')`;
                url += `&filterByFormula=${encodeURIComponent(filterFormula)}`;

                const response = await fetch(url, {
                    method: 'PATCH',
                    headers: airTableHeaders,
                });

                if (response.ok) {
                    const userData = await response.json();
                    // Check if records array is not empty, and return the first record if found
                    if (userData.records && userData.records.length > 0) {
                        return userData.records[0];
                    } else {
                        throw new Error('User not found');
                    }
                } else {
                    Sentry.captureException(response);
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                handleError(error);
                throw error;
            }
        },
        async fetchUser(userId) {
            const url = `${REMOTE_FETCH}?table=Users/${userId}`;
            try {
                const response = await fetch(url, {
                    headers: airTableHeaders,
                });

                // check if the fetch was successful
                if (response.ok) {
                    const userData = await response.json();
                    return userData;
                } else {
                    Sentry.captureException(response);
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                handleError(error);
                throw error;
            }
        },
        // latLong = 42.3478,-71.0466
        async fetchWeather(latLong) {
            // const url = `${TOMORROW_IO_URL}&location=${latLong}`;
            const url = `${TOMORROW_IO_URL}&location=${latLong}&timesteps=1d&fields=weatherCodeDay,precipitationProbability,temperatureApparentMax`;
            try {
                const response = await fetch(url, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                // check if the fetch was successful
                if (response.ok) {
                    const userData = await response.json();
                    return userData;
                } else {
                    handleError(response.status);
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                handleError(error);
                throw error;
            }
        },
        async fetchAllMountainsWithFields(offset = null) {
            const requiredFields = [
                "ID", "Name", "Lat", "Long", "Camping? Text", "Uphill? Text",
                "XC?", "Pass Names", "Region Names", "Address"
            ];

            let url = `${REMOTE_FETCH}?table=Mountains&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc`;
            if (offset) {
                url += `&offset=${offset}`;
            }

            // Add each field as a separate fields[] parameter
            // requiredFields.forEach(field => {
            //     url += `&fields%5B%5D=${encodeURIComponent(field)}`;
            // });

            try {
                const response = await fetch(url, {
                    headers: airTableHeaders,
                });

                if (!response.ok) {
                    let msg = `HTTP error! Status: ${response.status}`;
                    handleError(msg);
                    throw new Error(msg);
                }

                const data = await response.json();

                // Map new records and filter out duplicates by ID
                const newMountains = data.records.map(record => ({ id: record.id, ...record.fields }));
                const uniqueMountains = newMountains.filter(
                    newMountain => !this.mountains.some(existingMountain => existingMountain.id === newMountain.id)
                );

                // Add only unique records to mountains array
                this.mountains.push(...uniqueMountains);

                // Sort mountains by Name
                this.mountains.sort((a, b) => a.Name.localeCompare(b.Name));

                // If there’s more data, call the function again after a delay
                if (data.offset) {
                    // Only 5 calls per second https://airtable.com/developers/web/api/rate-limits
                    await new Promise(resolve => setTimeout(resolve, this.timeout)); // 250 ms delay
                    return this.fetchAllMountainsWithFields(data.offset);
                }

            } catch (error) {
                handleError('Error fetching all mountains:', error);
                throw error;
            }
        },
    },
    mounted() {
        // this.fetchMountains();
    },
};
