export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://www.firstchair.ski/',
	},
	{
		id: 2,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://instagram.com/firstchair.ski',
	},
	{
		id: 3,
		name: 'Email',
		icon: 'mail',
		url: 'mailto:hello@firstchair.ski?subject=Hey Ryan!',
	},
];
