export const AIRTABLE_API_ENDPOINT= process.env.AIRTABLE_API_ENDPOINT;
export const AIRTABLE_API_KEY = process.env.AIRTABLE_API_KEY;
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_API_ENDPOINT = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCewWg4tRkbGJojAxS8oXfBycWtPPgyoL4&loading=async&callback=initMap`;
export const TOMORROW_IO_API_KEY = process.env.TOMORROW_IO_API_KEY;
export const TOMORROW_IO_URL = `https://api.tomorrow.io/v4/timelines?units=imperial`;

let remoteFetchURL;
if (process.env.NODE_ENV === 'development') {
    remoteFetchURL = 'http://localhost:9999/.netlify/functions/remote-fetch'
} else {
    remoteFetchURL = 'https://firstchair.ski/.netlify/functions/remote-fetch'
}
export const REMOTE_FETCH = remoteFetchURL;
