<script>
import AppBanner from '@/components/shared/AppBanner';
import MountainsGrid from '../components/mountains/MountainsGrid.vue';
import Button from '../components/reusable/Button.vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		MountainsGrid,
		Button,
	},
};
</script>

<template>
	<div class="container mx-auto">
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />
	</div>

  <div class="mt-10 sm:mt-20 bg-primary-light dark:bg-ternary-dark shadow-sm py-6 sm:py-12">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 dark:text-white">Easily Track Your Favorite Ski Resorts</h2>
      <h4 class="text-md sm:text-lg md:text-xl text-gray-600 dark:text-gray-300 mt-2 sm:mt-4">Days counter, quick links, distance and driving directions, Instagram hotlinks</h4>
      <div class="mt-4 sm:mt-6 flex justify-center">
        <img src="@/assets/images/screenshot1.png" class="rounded-xl w-full max-w-md" alt="Example firstchair.ski screenshot" />
      </div>
    </div>
  </div>

	<div class="container mx-auto">
    <!-- Mountains -->
    <MountainsGrid :mountainLimit=16 :showFilters="false" :onlyLogos="true" />

    <!-- Load more mountains button -->
    <div class="mt-10 sm:mt-20 flex justify-center">
      <router-link
          to="/mountains"
          class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
          aria-label="More Mountains"
      >
        <Button title="All Mountains..." />
      </router-link>
    </div>
  </div>

  <div class="mt-10 sm:mt-20 bg-primary-light dark:bg-ternary-dark shadow-sm py-6 sm:py-12">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 dark:text-white">Now with dark mode! And a HUGE map!</h2>
      <div class="mt-4 sm:mt-6 flex justify-center">
        <router-link to="/map" class="" aria-label="Map">
          <img src="@/assets/images/screenshot2.png" class="rounded-xl w-full max-w-xl pr-2" alt="Example firstchair.ski screenshot" />
        </router-link>
        <router-link to="/map" class="" aria-label="Map">
          <img src="@/assets/images/screenshot3.png" class="rounded-xl w-full max-w-xl pl-2" alt="Example firstchair.ski screenshot" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
