<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10"
	>
		<!-- Banner left contents -->
		<div class="w-full md:w-1/3 text-left">
			<h1
				class="font-general-semibold text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase"
			>
        Track your ski days!
			</h1>
			<p
				class="font-general-medium mt-2 text-lg sm:text-xl  xl:text-2xl text-center sm:text-left leading-none text-gray-400"
			>
        Plan the mountains (or back-country) you're going to hit first chair, uphill skin or rip the fresh gnar pow.
      </p>
      <div class="flex justify-center">
        <a href="/mountains"
           class="flex justify-center items-center mt-4 sm:mt-12 mb-4 sm:mb-6 px-2 sm:px-6 text-sm sm:text-lg border border-indigo-200 dark:border-ternary-dark py-2 sm:py-3 shadow-lg rounded-lg bg-indigo-50 focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-gray-500 hover:text-white duration-500"
           aria-label="Get started">
          <i data-feather="arrow-down-circle"
             class="w-5 sm:w-6 duration-100"></i>
          <span class="ml-2 sm:ml-3 text-sm sm:text-lg font-general-medium duration-100">See the mountains</span>
        </a>
      </div>
    </div>

		<!-- Banner right illustration -->
		<div class="w-full md:w-1/2 text-right float-right radius">
			<img
				v-if="theme === 'light'"
				src="@/assets/images/home-ski-tips.jpeg"
				alt="Skier with mountains"
			/>
			<img
				v-else
        src="@/assets/images/home-ski-tips.jpeg"
        alt="Skier with mountains"
			/>
		</div>
	</section>
</template>

<style scoped></style>
