<script>
export default {
	props: ['mountain'],
};
</script>

<template>
	<router-link
      :to="{ name: 'mountain', params: { id: mountain['ID'] } }"
		class="grid auto-rows-auto rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
		aria-label="Single Mountain"
	>
    <div class="flex items-center justify-center"> <!-- Added classes here -->
      <img v-if="mountain.Logo"
           :src="mountain.Logo[0].url"
           :alt="mountain.Name"
           class="rounded-lg p-2.5 border-none max-h-150"
			/>
      <img v-else
          src="@/assets/images/150x150.png"
          :alt="mountain.Name"
          class="rounded-lg p-2.5 border-none max-h-150"
      />
		</div>
		<div class="text-center place-self-end mx-auto px-4 py-6">
			<p
				class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
			>
				{{ mountain.Name }}
			</p>
      <span
          v-for="pass in mountain['Pass Names']"
          :key="pass"
          class="font-general-medium text-sm text-ternary-dark dark:text-ternary-light bg-blue-100 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900"
      >
				{{ pass }}
			</span>
      <div class="clear-both"></div>
      <span
          v-for="region in mountain['Region Names']"
          :key="region"
          class="font-general-medium text-sm text-ternary-light dark:text-ternary-dark bg-blue-500 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-100"
      >
				{{ region }}
			</span>
		</div>
	</router-link>
</template>

<style lang="scss" scoped></style>
