import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/app.css';
import BackToTop from 'vue-backtotop';
import * as Sentry from "@sentry/vue";

const feather = require('feather-icons');
feather.replace();

const app = createApp(App)

let env = "production"
if (process.env.NODE_ENV === "development") {
	env = "development"
}

Sentry.init({
	app,
	dsn: "https://f8bfac236a567e8f88b61027a45a3aca@o4506219413897216.ingest.sentry.io/4506219501715456",
	environment: env,
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ["*"], // https://stackoverflow.com/questions/71804069/sentry-get-cors-error-in-localhost-by-vuejs3
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootComponent = app
	.use(router)
	.use(BackToTop)
	.mount('#app');

const appTheme = localStorage.getItem('theme');

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
	appTheme === 'dark' &&
	document.querySelector('body').classList.contains('app-theme')
) {
	document.querySelector('body').classList.add('bg-primary-dark');
} else {
	document.querySelector('body').classList.add('bg-secondary-light');
}
