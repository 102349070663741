<script>
export default {
	props: {
		select: {
			type: String,
			default: 'mountains'
		},
		selectOptions: {
			type: Array,
			default: () => [
				'Epic',
				'Epic Partner',
				'Ikon',
				'Indy',
				'Indy AddOn',
				'Mountain Collective',
        'None',
				'Powder Alliance',
				'Uphill New England',
			],
		},
	},
};
</script>

<template>
	<select
		@change="$emit('filter', $event.target.value)"
		:name="select"
		:id="select"
    class="w-60 font-general-medium px-4 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-lg text-sm sm:text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light">
		<option :value="[]" class="text-sm sm:text-md">All Passes</option>
		<option
			v-for="option in selectOptions"
			:key="option"
			:value="option"
			class="sm:text-md">
			{{ option }}
		</option>
	</select>
</template>

<style lang="scss" scoped></style>
